/*Learning Home CSS*/
*{
    caret-color: transparent;
}
.learning-home{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.home-header{
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top:4vh;
    width: 100%;
    padding: 0 70px;
    z-index: 5;
}
.home-header .logo{
    width: 6.771vw;
}
.home-header .logo img{
    width: 100%;
    min-width: 70px;
}
.home-menu{
    display: flex;
}
.home-menu>span{
    margin-right:13px;
    position: relative;
    max-width: 80px;
    width: 4.218vw;
    height: 4.218vw;
    min-width: 50px;
    min-height: 50px;
}
.home-menu>span:last-child{
    margin-right:0;
}
.home-menu>span>img{
    cursor: pointer;
    width: 100%;
}
.photo-upload{
    width:1.5vw;
    height:1.5vw;
    background: url(../images/home-new/photo-upload.png)center no-repeat;
    background-size:100%;
    border-radius: 50%;
    position: absolute;
    right:0;
    bottom:0;
    display:block;
    cursor:pointer;
}
.learning-home .my-message{
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(50 50 50 / 28%) 1px 2px 3px;
    width: 12.656vw;
    height: auto;
    min-width: 192px;
    min-height: 139px;
    border-radius: 1.5vw;
    padding: 1.041vw 0px 1.041vw 1.302vw;
    position: absolute;
    left: 50%;
    top: calc(4.218vw + 20px);
    transform:translateX(-50%);
}
.learning-home .my-message .top{
    position: relative;
}
.learning-home .my-message .arrow{
    border-bottom: 1vw solid rgb(255, 255, 255);
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
    position: absolute;
    top: -0.7vw;
    left: 50%;
    margin-left: -0.5vw;
}
.learning-home .my-message .message-icon{
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    min-width: 17px;
    min-height: 17px;
}
.learning-home .my-message .message-icon img{
    width: 100%;
}
.learning-home .my-message .about-me{
    margin-left: 5px;
    width: calc(100% - 2.25vw - 5px);
}
.learning-home .my-message .about-me p{
    font-size: 1.041vw;
    line-height: 1.302vw;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.learning-home .my-message .about-me span {
    color: rgb(141, 141, 141);
    font-size: 1.041vw;
    display: block;
    word-break: break-word;
}

.learning-home .side-menu {
    position: absolute;
    right: -4%;
    top: 10vh;
}
.learning-home .side-menu .menu-box {
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgb(50 50 50 / 28%) 2px 2px 3px;
    cursor: pointer;
    margin-bottom: 1.388vw;
    width: 7vw;
    height: 7vw;
}
.learning-home .side-menu .menu-box>div{
    text-align: center;
}
.learning-home .side-menu .menu-box:last-child{
    margin-bottom: 0;
}
.learning-home .side-menu .menu-box img {
    margin-top: 1.851vh;
}
.learning-home .side-menu .menu-box .side-menu-1{
    width: 3.5vw;
}
.learning-home .side-menu .menu-box .side-menu-2{
    width: 3.5vw;
}
.learning-home .side-menu .menu-box .side-menu-3{
    width: 3.5vw;
}
.learning-home .side-menu .menu-box p {
    color: rgb(122, 122, 122);
    font-size: 22px;
    margin-top: 3px;
    text-align: center;
}

.home-contents{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    position: relative;
    height: 100vh;
    padding-top: 34.49vh;
}

.home-img{
    position: absolute;
    left: 0;
    top: -34.49vh;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    height: 34.49vh;
    padding-top: 4vh;
}
.home-img img{
    position: absolute;
    bottom: -6%;
    height: 100%;
    max-height: 335px;
}
.home-box{
    width: 91.25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 250px 0px 0px;
    padding: 5vh 0px;
    position: relative;
}
/*hht1 에피소드별 배경색*/
/*ep01*/
.home-contents.box-background-hh1{background-color: #f6f4df;}
.ep1 .box-background-hh1 .home-box{background-color: #fec830;}
.ep2 .box-background-hh1 .home-box{background-color: #89d0c8;}
.ep3 .box-background-hh1 .home-box{background-color: #f48f98;}
.ep4 .box-background-hh1 .home-box{background-color: #acc939;}
.ep5 .box-background-hh1 .home-box{background-color: #f5a9cb;}
.ep6 .box-background-hh1 .home-box{background-color: #8aa7d6;}
.ep7 .box-background-hh1 .home-box{background-color: #b7a4ce;}
.ep8 .box-background-hh1 .home-box{background-color: #7bcbbf;}
.ep9 .box-background-hh1 .home-box{background-color: #84d1d9;}
.ep10 .box-background-hh1 .home-box{background-color: #c9db43;}
.ep11 .box-background-hh1 .home-box{background-color: #b3d234;}
.ep12 .box-background-hh1 .home-box{background-color: #83c0e9;}
.ep13 .box-background-hh1 .home-box{background-color: #f9936f;}
.ep14 .box-background-hh1 .home-box{background-color: #99d0e8;}
.ep15 .box-background-hh1 .home-box{background-color: #fec463;}
.ep16 .box-background-hh1 .home-box{background-color: #e4a5ca;}
.ep17 .box-background-hh1 .home-box{background-color: #f5cd6d;}
.ep18 .box-background-hh1 .home-box{background-color: #94d2d8;}
.ep19 .box-background-hh1 .home-box{background-color: #96a1d4;}
.ep20 .box-background-hh1 .home-box{background-color: #f6a1a7;}

/*hht2 에피소드별 배경색*/
/*ep01*/
.home-contents.box-background-hh2{background-color: #458fd6;}
.box-background-hh2 .home-box{background-color: #425fa3;}
.box-background-hh2 .home-box:before{background-color: #425fa3;}
.box-background-hh2 .home-box:after{background-color: #458fd6;}

/*레슨별 아이템 컬러*/
/*퀴즈타임*/
/*.ls1 .third .item-box{border-color: #f66445;}*/
/*.ls1 .third .top-text{background-color: #f66445;}*/
/*.ls1 .third .top-text:before{background-color: #f66445;}*/
/*.ls1 .third .bot-text{background-color: #f66445;}*/
/*!*스토리북*!*/
/*.ls2 .first .item-box{border-color: #4bacc6;}*/
/*.ls2 .first .top-text{background-color: #4bacc6;}*/
/*.ls2 .first .top-text:before{background-color: #4bacc6;}*/
/*.ls2 .first .bot-text{background-color: #4bacc6;}*/
/*!*송타임*!*/
/*.ls2 .third .item-box{border-color: #8b10d7;}*/
/*.ls2 .third .top-text{background-color: #8b10d7;}*/
/*.ls2 .third .top-text:before{background-color: #8b10d7;}*/
/*.ls2 .third .bot-text{background-color: #8b10d7;}*/
/*!*워드플레이*!*/
/*.ls3 .first .item-box{border-color: #90cff2;}*/
/*.ls3 .first .top-text{background-color: #90cff2;}*/
/*.ls3 .first .top-text:before{background-color: #90cff2;}*/
/*.ls3 .first .bot-text{background-color: #90cff2;}*/
/*!*타임라인*!*/
/*.ls3 .third .item-box{border-color: #9ce489;}*/
/*.ls3 .third .top-text{background-color: #9ce489;}*/
/*.ls3 .third .top-text:before{background-color: #9ce489;}*/
/*.ls3 .third .bot-text{background-color: #9ce489;}*/

.epi-step{
    display: flex;
    align-items: center;
    justify-content: center;
}
.epi-step .epi-arrow{
    width:61px;
    height: 61px;
    border-radius:50%;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/home-new/arrow-sky.png);
    background-size: 19px;
    cursor: pointer;
}
.epi-step .epi-arrow.next{
    transform:rotate(180deg);
}
.epi-step .epi-step-p{
    font-family: 'NanumSquareRound',sans-serif;
    font-size: 56px;
    color:#fff;
    font-weight: 900;
    margin:0 28px;
}

.epi-lesson{
    margin-top: 2.24vh;
    margin-bottom: 4.48vh;
    display:flex;
    align-items: center;
    justify-content: center;
}
.learning-home .lesson-bar{
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 100px;
    position: relative;
    z-index: 5;
}
.box-background-hh1 .lesson-bar:before{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: #fff;
    border-radius: 100px;
    box-shadow: -1px -3px 3px rgba(0,0,0,.13);
}
.learning-home .lesson-bar>li{
    cursor: pointer;
    background-color: rgb(243, 243, 243);
    padding: 5px 30px;
    border: 4px solid rgb(243, 243, 243);
    border-radius: 100px;
}
.learning-home .lesson-bar>li span{
    font-family: 'NanumSquareRound',sans-serif;
    font-size:1.6vw;
    color:#d2d2d2;
    font-weight: 700;
    position: relative;
    z-index: 2;
    line-height: 1.6;
}

.box-background-hh1 .epi-lesson[data-menu-selected="1"] .lesson-bar:before{
    width: 20%;
}
.box-background-hh1 .epi-lesson[data-menu-selected="2"] .lesson-bar:before{
    width: 40%;
}
.box-background-hh1 .epi-lesson[data-menu-selected="3"] .lesson-bar:before{
    width: 60%;
}
.box-background-hh1 .epi-lesson[data-menu-selected="4"] .lesson-bar:before{
    width: 80%;
}
.box-background-hh1 .epi-lesson[data-menu-selected="5"] .lesson-bar:before{
    width: 100%;
}

.box-background-hh1 .epi-lesson[data-menu-selected="2"] .list-1 span{
    color:#000;
}
.box-background-hh1 .epi-lesson[data-menu-selected="3"] .list-1 span, .box-background-hh1 .epi-lesson[data-menu-selected="3"] .list-2 span{
    color:#000;
}
.box-background-hh1 .epi-lesson[data-menu-selected="4"] .list-1 span, .box-background-hh1 .epi-lesson[data-menu-selected="4"] .list-2 span, .box-background-hh1 .epi-lesson[data-menu-selected="4"] .list-3 span{
    color:#000;
}
.box-background-hh1 .epi-lesson[data-menu-selected="5"] .list-1 span, .box-background-hh1 .epi-lesson[data-menu-selected="5"] .list-2 span, .box-background-hh1 .epi-lesson[data-menu-selected="5"] .list-3 span, .box-background-hh1 .epi-lesson[data-menu-selected="5"] .list-4 span{
    color:#000;
}
.learning-home .lesson-bar>li:hover span{
    color: #000;
}

.learning-home .lesson-bar>li.now{
    background-color: #5ac3db;
    border: 4px solid #fff;
    border-radius: 100px;
    z-index: 5;
}
.learning-home .lesson-bar>li.now span{
    color:#fff;
    opacity: 1;
}

.epi-category{
    display: flex;
    justify-content: center;
    padding: 0 80px;
}
.item-box-parent{
    margin:0 0.833vw;
    border-radius: 38px;
    cursor: pointer;
    position: relative;
    border-radius: 38px;
}
.item-box{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 38px;
    overflow: hidden;
}
.item-box img{
    width: 20.625vw;
    max-width: 396px;
}
.top-text{
    position: absolute;
    top: 0;
    left: 2px;
    padding: 13px 30px 5px 30px;
    box-shadow: rgb(50 50 50 / 20%) 5.5px 8px 6px;
    border-radius: 38px 0 45px 0;
}
.top-text:before{
    content: '';
    position: absolute;
    right: 0;
    top:0;
    height: calc(100% - 1px);
    width:25%;
    border-radius: 10px 0px 20px;
    transform: skew(-20deg);
    z-index: 1;
    box-shadow: rgb(50 50 50 / 20%) 5.5px 0 6px;
}
.top-text p {
    font-family: 'NanumSquareRound', sans-serif;
    font-size: 1.4vw;
    color: #fff;
    position: relative;
    z-index: 2;
}

@media (min-width: 2000px) {

    /* HHT1, HHT2 퍼블리싱 구조가 달라서 분리 필요*/
    /*
    .home-box{
        display: block;
    }

    .episode_wrap{
        text-align: center;
    }

    .epi-step{
        display: inline-flex;
    }

    .epi-step .epi-arrow{
        width:100px;
        height: 100px;
        background-size: 30px;
    }

    .epi-step .epi-step-p{
        font-size: 80px;
    }

    .epi-lesson{
        margin-top: 140px;
        margin-bottom: 140px;
    }
    */
    .item-box img{
        max-width: 600px;
    }

    .top-text p {
        font-size: 36px;
    }

    .home-img img{

        bottom: -21px;
    }

    .home-box-top .home-img img{
        bottom: -24px !important;
    }

    /* 4k 사이드메뉴 크기줄임 */
    .learning-home .side-menu {
        right: -90px;
    }

    .learning-home .side-menu .menu-box {
        width: 180px;
        height: 180px;
    }

    .learning-home .side-menu .menu-box .side-menu-1{
        width: 100px;
    }
    .learning-home .side-menu .menu-box .side-menu-2{
        width: 100px;
    }
    .learning-home .side-menu .menu-box .side-menu-3{
        width: 100px;
    }

}
.bot-text{
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 0px 0px 25px;
    padding: 8px 20px 8px 35px;
}
.bot-text p{
    font-family: 'NanumSquareRound',sans-serif;
    font-size: 22px;
    color:#fff;
}
.bot-text .icon{
    position: absolute;
    top: -4px;
    left: 0px;
    border-radius: 50%;
    transform: translate(-50%, 0px);
    width: 55px;
    height: 55px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgb(50 50 50 / 20%) -1px 3px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bot-text .icon.ready{background-color: rgb(193, 193, 193);}
.bot-text .icon.progress{background-color: rgb(247, 98, 74);}
.bot-text .icon.completed{background-color: rgb(167, 210, 92);}
.bot-text .icon img{
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
.bot-text .icon .icon-1{
    min-width: 16px;
    width: 1.25vw;
}
.bot-text .icon .icon-2{
    min-width: 16px;
    width: 1.25vw;
}
.bot-text .icon .icon-3{
    min-width: 16px;
    width: 1.25vw;
}
/*.first .item-box{border: 8px solid rgb(253, 133, 109);}*/
/*.first .top-text{background-color:rgb(253, 133, 109);}*/
/*.first .top-text:before{background-color: rgb(253, 133, 109);}*/
/*.first .bot-text{background-color: rgb(253, 133, 109);}*/
/*.second .item-box{border: 8px solid rgb(178, 133, 250);}*/
/*.second .top-text{background-color:rgb(178, 133, 250);}*/
/*.second .top-text:before{background-color: rgb(178, 133, 250);}*/
/*.second .bot-text{background-color: rgb(178, 133, 250);}*/
/*.third .item-box{border: 8px solid rgb(109, 205, 227);}*/
/*.third .top-text{background-color:rgb(109, 205, 227);}*/
/*.third .top-text:before{background-color: rgb(109, 205, 227);}*/
/*.third .bot-text{background-color: rgb(109, 205, 227);}*/

/*활동 별 컬러*/
.storybook-thumb {border: 8px solid #60bcd3}
.storybook-thumb-txt {background-color: #60bcd3}
.storybook-thumb-txt:before{background-color: #60bcd3}
.animation-thumb {border: 8px solid #fd856d}
.animation-thumb-txt {background-color: #fd856d}
.animation-thumb-txt:before{background-color: #fd856d}
.sitcom-thumb {border: 8px solid #fd856d}
.sitcom-thumb-txt {background-color: #fd856d}
.sitcom-thumb-txt:before{background-color: #fd856d}
.wordplay-thumb {border: 8px solid #c06a3a}
.wordplay-thumb-txt {background-color: #c06a3a}
.wordplay-thumb-txt:before{background-color: #c06a3a}
.minisitcom-thumb {border: 8px solid #b285fa}
.minisitcom-thumb-txt {background-color: #b285fa}
.minisitcom-thumb-txt:before{background-color: #b285fa}
.speakingpractice-thumb {border: 8px solid #b285fa}
.speakingpractice-thumb-txt {background-color: #b285fa}
.speakingpractice-thumb-txt:before{background-color: #b285fa}
.quiztime-thumb {border: 8px solid #f09199}
.quiztime-thumb-txt {background-color: #f09199}
.quiztime-thumb-txt:before{background-color: #f09199}
.storybook-thumb {border: 8px solid #60bcd3}
.storybook-thumb-txt {background-color: #60bcd3}
.storybook-thumb-txt:before{background-color: #60bcd3}
.songtime-thumb {border: 8px solid #ee7443}
.songtime-thumb-txt {background-color: #ee7443}
.songtime-thumb-txt:before{background-color: #ee7443}
.listenup-thumb {border: 8px solid #ee7443}
.listenup-thumb-txt {background-color: #ee7443}
.listenup-thumb-txt:before{background-color: #ee7443}
.singalong-thumb {border: 8px solid #ee7443}
.singalong-thumb-txt {background-color: #ee7443}
.singalong-thumb-txt:before{background-color: #ee7443}
.timeline-thumb {border: 8px solid #8dc85a}
.timeline-thumb-txt {background-color: #8dc85a}
.timeline-thumb-txt:before{background-color: #8dc85a}
.eggstudionew-thumb {border: 8px solid #4873aa}
.eggstudionew-thumb-txt {background-color: #4873aa}
.eggstudionew-thumb-txt:before{background-color: #4873aa}
.intonation-thumb {border: 8px solid #75afdf}
.intonation-thumb-txt {background-color: #75afdf}
.intonation-thumb-txt:before{background-color: #75afdf}

/*.animation-thumb {border: 8px solid #60bcd3}*/
/*.storybook-thumb {border: 8px solid #60bcd3}*/
/*.storybook-thumb {border: 8px solid #60bcd3}*/

.modal_wrapper.character-pop{
    transform: translate(-50%, -50%);
}
.character-pop .modal_inner .title{
    font-size:44px;
}
.character-list{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 35px;
    width: 690px;
}
.character-list span{
    margin-right: 18px;
    background-color: #b1dfe7;
    overflow: hidden;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border:2px solid #b1dfe7;
}
.character-list span:hover{
    border:2px solid #45b6c9;
}
.character-list span.my-photo{
    overflow: inherit;
}
.character-list span:nth-child(6n){
    margin-right: 0;
}
.character-list span:nth-child(n+7){
    margin-top: 18px;
}
.character-list span img{
    width: 100%;
}

.modal_wrapper.upload-pop{
    padding: 33px 52px;
    transform: translate(-50%, -50%);
}
.modal_wrapper.upload-pop .modal_inner{
    width: 100%;
}
.upload-pop .modal_inner .title{
    font-size:44px;
    text-align: left;
    padding-left: 66px;
    background: url(../images/home-new/upload-icon.png)left center no-repeat;
}
.photo-area{
    font-family: 'NanumSquareRound',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px dashed #6dcde3;
    border-radius: 20px;
    margin-top: 35px;
    width: 730px;
    cursor: pointer;
    overflow: hidden;
}
.photo-area img{
    width: 100%;
}
.photo-area .defalut{
    text-align: center;
    padding-top: 125px;
    background: url(../images/home-new/character_d2.png)center top no-repeat;
    margin: 22px 0;
}
.photo-area .defalut p{
    font-size: 25px;
    color: #585858;
    font-weight: 600;
    line-height: 1.2;
}
.photo-area .defalut .full-btn{
    font-size: 25px;
    color:#fff;
    padding: 2px 10px;
    width: 277px;
    border-radius: 48px;
}

.modal_wrapper.photo-pop{
    min-width: auto;
    overflow: hidden;
    padding: 0;
    transform: translate(-50%, -50%);
    min-width: 350px;
    min-height: 350px;
}
.modal_wrapper.photo-pop img{
    width: 100%;
}
.modal_wrapper.photo-pop .full-btn{
    font-size: 25px;
    color:#fff;
    padding: 2px 10px;
    width: 147px;
    border-radius: 48px;
    position: absolute;
    bottom:45px;
    left: 50%;
    margin-left: -73px;
}


/*hht2*/
.box-background-hh2.home-contents{
    padding-top: 55vh;
}
.box-background-hh2 .home-box-top{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    padding-top: 5vh;
    height: 55vh;
}
.box-background-hh2 .lesson-box{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 10vh;
}
.home-box-top .epi-lesson{
    margin: 0;
}
.home-box-top .epi-step{
    position: absolute;
    left: 50%;
    top: 5vh;
    transform: translateX(-50%);
    z-index: 5;
}
.home-box-top .epi-arrow{background-image: url(../images/home-new/arrow-blue.png);}
.home-box-top .home-img{
    position: relative;
    height: 100%;
    top: 0;
    width: 41%;
    margin:0 7%;
}
.home-box-top .home-img:before, .home-box-top .home-img:after{
    content: '';
    position: absolute;
    bottom:0;
}
.home-box-top .home-img:before{
    width: 75%;
    height: 414px;
    background: url(../images/home-new/left-light.png)left bottom -2vw no-repeat;
    left: -40px;
    background-size: 100%;
}
.home-box-top .home-img:after{
    width: 110%;
    height: 386px;
    background: url(../images/home-new/right-light.png)right bottom -3vw no-repeat;
    right: -60px;
    background-size: 100%;
}
.home-box-top .home-img img{
    bottom: -4.5%;
    max-height: 505px;
    z-index: 5;
    height: 100% !important;
}
.box-background-hh2 .lesson-box{
    margin-left: -6vw;
}
.box-background-hh2 .lesson-bar{
    background: none;
    flex-wrap: wrap;
    width: 11vw;
    min-width: 155px;
}
.box-background-hh2 .lesson-bar>li{
    background: none;
    margin-top:2vh;
    background-color: #458fd6;
}
.box-background-hh2 .lesson-bar>li:first-child{
    margin-top:0;
}
.box-background-hh2 .lesson-bar>li>span{
    color:#fff;
}
.box-background-hh2 .lesson-bar>li.now, .box-background-hh2 .lesson-bar>li:hover{
    background: #fff;
}
.box-background-hh2 .lesson-bar>li.now>span, .box-background-hh2 .lesson-bar>li:hover>span{
    color:#458fd6;
}
.box-background-hh2 .home-box{
    border-radius: 650px 0 0 0;
    width: 100%;
}
.box-background-hh2 .home-box:before{
    content: '';
    position: absolute;
    right:0;
    top:-19vw;
    width: 30%;
    height: 19vw;
}
.box-background-hh2 .home-box:after{
    content: '';
    position: absolute;
    right:0;
    top:-19vw;
    width: 30%;
    height: 19vw;
    border-radius:0 0 650px 0;
}
.box-background-hh2 .side-menu{
    position: relative;
    right:0;
    top: -15%;
    z-index: 5;
}
.box-background-hh2 .epi-category{
    padding: 0 5vh;
}

/*CULTURE*/
.box-background-culture .home-box{
    background-image: url("../images/banner-color-culture.png");
}

@media (max-width: 1650px) {
    .home-box{
        border-radius:0 200px 0 0;
    }
    .top-text{
        padding: 10px 20px 5px 20px;
        box-shadow: rgb(50 50 50 / 20%) 3.5px 5px 4px;
        border-radius: 38px 0 30px 0;
    }
    .item-box img{
        width: 23vw;
        min-width: 200px;
    }
    .bot-text{
        padding: 6px 15px 6px 25px;
    }
    .bot-text p{
        font-size: 15px;
    }
    .bot-text .icon{
        width: 37px;
        height: 37px;
        border: 3px solid rgb(255, 255, 255);
        box-shadow: rgb(50 50 50 / 20%) -1px 2px 2px;
    }
    .learning-home .side-menu .menu-box{
        width: 8vw;
        height: 8vw;
        min-width: 95px;
        min-height: 95px;
        max-width: 120px;
        max-height: 120px;
    }
    .learning-home .side-menu .menu-box p{
        font-size: 19px;
        margin-top: 3px;
    }
    .learning-home .side-menu .menu-box .side-menu-1{
        min-width: 45px;
        max-width: 58px;
    }
    .learning-home .side-menu .menu-box .side-menu-2{
        min-width: 38px;
        max-width: 52px;
    }
    .learning-home .side-menu .menu-box .side-menu-3{
        min-width: 42px;
        max-width: 57px;
    }
    .learning-home .lesson-bar>li{
        padding: 5px 23px;
    }
    .epi-step .epi-step-p{
        font-size: 45px;
    }

    .box-background-hh2 .home-box{
        align-items: center;
    }
    .box-background-hh2.home-contents {
        padding-top: 52vh;
    }
    .box-background-hh2 .home-box-top{
        height: 52vh;
    }
    .box-background-hh2 .item-box img{
        width: 21vw;
    }
}
@media (max-width: 1366px) {
    .home-box{
        padding: 50px 0px;
        align-items: flex-start;
    }
    .learning-home .lesson-bar>li span{
        font-size: 25px;
    }
    .modal_wrapper.upload-pop{
        width: 59vw;
    }
    .photo-area{
        width: 100%;
    }
    .learning-home .my-message{
        min-height: 15vh;
    }
    .home-img img{
        height: calc(100% - 20px);
    }

}

@media (max-width: 1200px) {
    .item-box-parent, .item-box{
        border-radius: 25px;
    }
    .learning-home .my-message {
        top: 65px;
    }
}

@media (max-width: 1024px), (max-height:768px) {
    .home-header{
        padding: 0 1.5vw 0 3vw;
    }
    .home-contents{
        padding-top: 32.49vh;
    }
    .home-box{
        padding: 4vh 0px;
        width: 94.25%;
    }
    .top-text p{
        font-size: 19px;
    }
    .item-box img{
        width: 24vw !important;
        max-width: 360px;
    }
    .learning-home .side-menu .menu-box{
        width: 13vh;
        height: 13vh;
    }
    .learning-home .side-menu .menu-box .side-menu-1{
        width: 6vh;
    }
    .learning-home .side-menu .menu-box .side-menu-2{
        width: 5vh;
    }
    .learning-home .side-menu .menu-box .side-menu-3{
        min-width: auto;
        width: 6vh;
    }
    .learning-home .lesson-bar>li span{
        font-size: 23px;
    }

    .epi-step .epi-step-p{
        font-size: 40px;
        margin: 0 17px;
    }
    .epi-step .epi-arrow{
        width: 40px;
        height: 40px;
        background-size:30%;
    }
    .home-menu>span{
        margin-right: 10px;
    }
    .learning-home .my-message{
        padding: 14px;
    }
    .learning-home .my-message .about-me p{
        font-size: 13px;
        line-height: 1.4;
    }
    .learning-home .my-message .about-me span{
        font-size: 12px;
    }
    .learning-home .my-message .arrow{
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 11px solid rgb(255, 255, 255);
        margin-left: -7px;
        top: -11px;
    }
    .character-pop .modal_inner .title{
        font-size: 2.8vw;
    }
    .character-list{
        width: 605px;
    }
    .character-list span{
        width: 90px;
        height: 90px;
        margin-right: 13px;
    }
    .character-list span:nth-child(n+7){
        margin-top: 13px;
    }
    .upload-pop .modal_inner .title{
        font-size: 2.8vw;
        background-size: 4vw;
        padding-left: 5vw;
    }
    .modal_wrapper.upload-pop {
        padding: 30px 35px;
        min-width: 470px;
    }
    .photo-area .defalut{
        padding-top: 9vw;
        background-size: 8vw;
    }
    .photo-area .defalut p{
        font-size: 1.8vw;
    }
    .photo-area .defalut .full-btn, .modal_wrapper.photo-pop .full-btn{
        font-size: 1.8vw;
        padding: 2px 2vw;
        width: auto;
        margin-left: auto;
    }
    .modal_wrapper.photo-pop .full-btn{
        bottom: 2vw;
    }
    .home-box-top .home-img{
        width: 41%;
        margin:0 4%;
    }
    .home-box-top .home-img:before{
        left:0;
    }
    .home-box-top .home-img:after{
        right:-25px;
    }
    .box-background-hh2 .lesson-box {
        margin-left: -11vw;
    }
    .box-background-hh2.home-contents{
        padding-top: 58vh;
    }
    .box-background-hh2 .home-box-top{
        height: 58vh;
    }
    .box-background-hh2 .home-box{
        padding: 0;
        align-items: center;
    }
    .box-background-hh2 .item-box img{
        width: 23.1vw !important;
    }
    .box-background-hh2 .epi-category {
        padding: 0 3vh;
    }
    .box-background-hh2 .lesson-bar{
        min-width: 150px;
    }
    .modal_wrapper.photo-pop .full-btn{
        transform: translateX(-50%);
    }
    .photo-upload{
        width: 1.8vw;
        height: 1.8vw;
        right: -0.2vw;
        bottom: -0.2vw;
    }
}

/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .home-box{
        border-radius: 0px 100px 0px 0px;
    }
    .top-text{
        padding: 8px 15px 5px 16px;
    }
    .top-text p {
        font-size: 1.5vw;
    }
    .learning-home .side-menu{
        right: -45px;
        top: 50%;
        transform: translateY(-50%);
    }
    .learning-home .side-menu .menu-box{
        width: 80px;
        height: 80px;
        min-width: auto;
        min-height: auto;
        margin-bottom: 1.2vw;
    }
    .learning-home .side-menu .menu-box p{
        font-size: 15px;
    }
    .learning-home .side-menu .menu-box .side-menu-1{
        min-width: auto;
        width: 38px;
    }
    .learning-home .side-menu .menu-box .side-menu-2{
        min-width: auto;
        width: 33px;
    }
    .learning-home .side-menu .menu-box .side-menu-3{
        min-width: auto;
        width: 36px;
    }
    .epi-step .epi-step-p{
        font-size: 35px;
        margin: 0 10px;
    }
    .epi-step .epi-arrow{
        width: 33px;
        height: 33px;
        background-size: 25%;
    }
    .learning-home .lesson-bar>li span{
        font-size: 20px;
    }
    .character-pop .modal_inner .title{
        font-size: 24px;
    }
    .character-list{
        margin-top: 20px;
        width: 515px;
    }
    .character-list span{
        width: 75px;
        height: 75px;
    }
    .upload-pop .modal_inner .title{
        font-size: 24px;
        background-size: 34px;
        padding-left: 43px;
    }
    .photo-area{
        margin-top: 20px;
    }
    .photo-area .defalut{
        padding-top: 101px;
        background-size: 55%;
    }
    .photo-area .defalut p{
        font-size: 17px;
    }
    .photo-area .defalut .full-btn, .modal_wrapper.photo-pop .full-btn{
        font-size: 17px;
        padding: 2px 20px;
    }

    .learning-home .my-message{
        min-height: 95px;
    }
    .epi-category{
        padding: 0 5%;
    }
    .item-box img {
        width: 25vw !important;
    }
    .box-background-hh2 .lesson-bar>li{
        border-width: 2px;
    }
    .box-background-hh2 .side-menu{
        right: 0;
        top: auto;
        bottom:32px;
        transform: none;
    }
    .box-background-hh2.home-contents{
        padding-top: 58vh;
    }
    .box-background-hh2 .home-box-top {
        height: 58vh;
    }
    .modal_wrapper.photo-pop{
        width: 70vh;
    }
    .photo-upload{
        width: 2vw;
        height: 2vw;
    }
    .learning-home .lesson-bar>li.now{
        border-width: 2px;
    }
    .item-box, .item-box-parent{border-radius: 20px;}
    .item-box{
        border-width:5px !important;
        height:auto;

    }
    .top-text{
        border-radius: 25px 0 30px 0;
    }
    .top-text:before{
        border-radius: 10px 0 17px;
    }
}

.bgm-control-box{
    position: absolute;
    top: 18vh;
    width: 8vw;
    left: 70px;
    z-index: 5;
}

.bgm-control-box .bcb-title{
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.bgm-control-box .bcb-inner-wrap{
    position: relative;
    width: 100%;
    border: 2px solid #fdd971;
    border-radius: 20px;
    background-color: #ffefc1;
}

.bgm-control-box .bcb-inner-wrap .split-half{
    width: 50%;
    float: left;
    text-align: center;
}

.bgm-control-box .bcb-inner-wrap .clear-float{
    clear:both;
}

.bgm-control-box .bcb-inner-wrap .bc-button{
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateY(calc(-100% + 4px));
    background-color: #ffe288;
    border: 4px solid #efa472;
    border-radius: 24px;
}

.bgm-control-box .bcb-inner-wrap .bgmOff{
    animation:bc-btn-slideOff 0.5s 1;
    animation-fill-mode: forwards;
}

@keyframes bc-btn-slideOff {
    0% {
        left: 0;
    }
    100% {
        left: 50%;
    }
}

.bgm-control-box .bcb-inner-wrap .bgmOn{
    animation:bc-btn-slideOn 0.5s 1;
    animation-fill-mode: forwards;
}

@keyframes bc-btn-slideOn {
    0% {
        left: 50%;
    }
    100% {
        left: 0%;
    }
}