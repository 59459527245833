/*Speaking Practice CSS*/
*{
    caret-color: transparent;
}
.practice-new{
    height: 100%;
    overflow: hidden;
    background-color: rgb(243, 247, 217);
    background-image: url(../images/background-pattern.png);
    background-repeat: repeat;
    background-size: auto;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.practice-box{
    height: 100%;
}
.practice-box .screen {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}
.practice-box .screen>div {
    position: relative;
    width: 100%;
    height: 100%;
}
.practice-wrap{
    width:100%;
    margin: 0 auto;
}
.practice-img{
    width: 100%;
    position: relative;
}
.practice-img img{
    display: inline-block;
    width: 100%;
}
.practice-img .listen-img{
    width: 120px;
    height: 120px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.play_btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 6vw;
    cursor: pointer;
}
.play_btn img {
    width: 100%;
    display: block;
}
.practice-text{
    text-align: left;
}
.practice-text .text{
    display: flex;
    align-items: center;
    margin-top: 0.7vw;
}
.practice-text span{
    /*width: 45px;*/
    /*height: 45px;*/
    border-radius: 50%;
    margin-right: 25px;
}
.practice-text span img{
    width: 100%;
    height:100%;
}
.practice-text p{
    font-size:3.5vh;
    font-weight: 600;
    max-width: calc(100% - 65px);
    cursor: pointer;
    line-height: 1.3;
}
.practice-text .point{
    font-style: normal;
    color:#4aaaee;
    font-weight: 800;
}

.practice-word-wrap{
    white-space: pre-wrap;
}

.btn-area.right{
    position: absolute;
    right: 0;
    top: 0;
    width: 6vw;
    cursor: pointer;
    margin-top: 0;
}
.btn-area.right img{
    width: 100%;
}

.position{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80vh;
    height: 100%;
    z-index: 3;
    transform: translate(-50%, -50%);
  }

@media (min-width:2000px) {
    .position{
        width: 80%;
    }

    .titleA{
        font-size: 1.4vw !important;
    }

    .practice-text p{
        font-size: 40px;
    }

    .practice-img .pi-media{
        width: 90% !important;
    }

    .practice-img .listen-img{
        right: calc(5% + 10px);
    }


    .practice-text .text{
        margin-top: 2px;
    }

    .practice-text:nth-child(2) .text{
        margin-top: 0.7vw;
    }
}

@media (max-height:850px) {
    .position{
        width: 68vh;
    }
}

@media (max-width: 1024px), (max-height:768px) {
    .position {
        width: 80vh;
    }
    .titleA{
        font-size: 5vh;
        margin-bottom: 2vh;
    }
    .play_btn{
        width: 10vh;
    }
    .btn-area.right{
        width: 10vh;
    }
    .btn-area.right img{
        height: auto;
    }
    .text-type1 .practice-text p{
        font-size: 3.8vh;
    }
}
@media (max-height:690px) {
    .text-type2 .practice-text span {
        width:40px;
        height:40px;
    }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .titleA{
        font-size: 33px;
        margin-bottom: 15px;
        padding-left: 43px;
    }
    .titleA:before{
        width: 35px;
        height: 26px;
        background-size: 100%;
    }
    .practice-text .text{
        margin-top: 1vh;
    }
    .position {
        width: 85vh;
    }
    .practice-text span {
        width: 40px;
        height: 40px;
        margin-right: 14px;
    }
    .practice-text p{
        max-width: calc(100% - 54px);
    }
    .text-type1 .practice-text p{
        font-size: 3.9vh;
    }
}
@media (max-height:600px) {
    .practice-box .screen {
        padding: 20px 30px 30px 30px;
    }
    .text-type2 .practice-text span {
        width:35px;
        height:35px;
    }
}
