.animation-new{
    height: 100%;
    overflow: hidden;
    background-color: rgb(243, 247, 217);
    background-image: url(../images/background-pattern.png);
    background-repeat: repeat;
    background-size: auto;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.animation-new>div{
    position: relative;
    height: 100%;
}
.animation-new .contents{
    min-height: 100%;
}

.animation-new .screen{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 60px;
}
.animation-box{
    height: 100%;
}
.animation-box .screen>div{
    position: relative;
    width: 100%;
}

/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .animation-new .screen{
        padding: 50px;
    }
}