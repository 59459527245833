/*Quiz Time CSS*/
*{
    caret-color: transparent;
}
.quiz-time{
    height: 100%;
    overflow: hidden;
    background-color: rgb(243, 247, 217);
    background-image: url(../images/background-pattern.png);
    background-repeat: repeat;
    background-size: auto;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.quiz-time>div{
    position: relative;
    height: 100%;
}
.quiz-time .contents{
    min-height: 100%;
}

.quiz-box{
    width: 100%;
    height: 100%;
}

.quiz-box .screen{
    display: flex;
    justify-content: center;
    text-align: center;
}
.word-quiz{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /*padding: 5% 0;*/
    height: 100%;
}

.question{
    font-family: 'NanumBarunGothic';
    font-size:2.9vw;
    font-weight: 800;
    word-break: keep-all;
    line-height: 1.1;
    display: inline-block;
    letter-spacing: -0.5px;
    cursor:pointer;
}

.quiz-layout{
    width:100%;
    display: flex;
    margin: 2vh 0;
    justify-content: center;
}
.quiz-layout>div{
    cursor: pointer;
    padding:10px 10px;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin:0 12px;
    border:6px solid #fff;
    border-radius: 20px;
    overflow:hidden;
}
.quiz-layout>div p{
    letter-spacing: 2px;
    font-family: 'NanumBarunGothic';
    font-size:1.64vw;
    font-weight: 600;
    margin-top:auto;
    margin-top: 1vh;
}

.quiz-layout>div:hover{
    border:6px solid #f66445;
}
.quiz-layout>div:focus{
    outline:none;
    border:6px solid #f66445;
}
.quiz-layout>div:hover p, .quiz-layout>div:focus p{
    color:#f66445;
}
.img-wrap{
    width: 100%;
    height: 100%;
    overflow: hidden;
    /*border-radius: 80px;*/
    /*padding:10px;*/
}
.img-wrap img{
    display: block;
}
/*단어퀴즈*/
.result-quiz{padding: 0;display: block;}
.result-quiz .question{
    text-align: left;
    font-size: 2.5vw;
    width: 100%;
    margin: 1% 0 1% 4%;
}
.result-quiz .img-wrap{
    width:85%;
    height: 85%;
}
.result-quiz .img-wrap img{
    max-height: 100%;
}
.word-quiz .quiz-layout>div{
    width: 28%;
}
.sentence-quiz .quiz-layout.w3>div{
    width: 31%;
}
.word-quiz .quiz-layout>div img, .sentence-quiz .quiz-layout.w3>div img{width: 100%;height: auto;}
/*문장퀴즈*/
.sentence-quiz{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: left;
}
.sentence-quiz .question{
    max-width: 40vw;
    font-size:2.9vw;
}
.sentence-quiz .quiz-layout>div{
    width: 33%;
}
.sentence-quiz .quiz-layout>div p{
    font-size:1.7vw;
    letter-spacing: -1px;
    width: 100%;
    line-height: 1.2;
    min-height: 4.2vw;
}
.sentence-quiz .quiz-layout>div img{
    width: 100%;
}
/*질문퀴즈*/
.answer-quiz .quiz-layout>div{
    position: relative;
}
.answer-quiz .quiz-layout>div img{
    width: 100%;
}
.answer-quiz .quiz-layout>div p{
    position: absolute;
    right:15%;
    top:15%;
}
.answer-quiz .quiz-layout>div:hover{
    border:6px solid #fff;
}

.btm_layer .text-wrap p .point{
    font-style: normal;
    color:red;
    font-weight: 800;
}
@media (max-width: 1388px) {
    .question{
        font-size:3.2vw;
    }
    .quiz-layout>div p{
        font-size: 2.5vw;
    }
}
@media (max-width: 1024px), (max-height:768px) {
    .question{
        font-size:3.2vw;
    }
    .quiz-layout>div p{
        font-size:2.2vw;
    }
    .sentence-quiz .question{
        font-size: 3.2vw;
    }
    .sentence-quiz .quiz-layout>div p{
        font-size: 2.2vw;
    }
    .word-quiz .quiz-layout>div, .sentence-quiz .quiz-layout.w3>div{
        margin: 0 5px;
        width: 30%;
    }
    .sentence-quiz .quiz-layout>div {
        margin: 0 5px;
    }
    .quiz-layout>div, .quiz-layout>div:hover{
        border-width: 3px;
    }
    .quiz-layout>div:focus{
        border-width: 3px;
    }
    .result-quiz .question{
        font-size: 3vw;
    }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .word-quiz{
        padding: 0 0 3% 0;
    }
    .sentence-quiz .quiz-layout>div p{
        font-size:21px;
    }
    .sentence-quiz .question{
        max-width: 50vw;
        font-size: 28px;
    }
    .record-wrap img{
        margin-right:15px;
    }
}

@media (min-width: 2000px) {

    /* 2022-10-06 http://localhost:3000/hh1/quiz-time 마진 탑 봇 3vh -> 1vh*/
    .quiz-layout{
        width: 100%;
        display: flex;
        margin: 1vh 0;
        justify-content: center;
        width: 100%;
    }

    /* 2022-10-06 */
    .question {
        font-family: 'NanumBarunGothic';
        font-size: 2vw;
        font-weight: 800;
        word-break: keep-all;
        line-height: 1.1;
        display: inline-block;
        letter-spacing: -0.5px;
    }
    /* 2022-10-06 */
    .result-quiz .question {
        text-align: left;
        font-size: 1.5vw;
        width: 100%;
        margin: 1% 0 2% 4%;
    }

}
