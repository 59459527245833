*{
  caret-color: transparent;
}
.time-line {
  height: 100%;
  overflow: hidden;
  background-color: rgb(243, 247, 217);
  background-image: url(../images/background-pattern.png);
  background-repeat: repeat;
  background-size: auto;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  /* overflow: hidden; */
}
.time-line .box_parent {
  padding: 115px 0 30px;
}
.time-line .box_parent .box-wrap {
  border: none;
  border-radius: 20px;
  display: block !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 1490px;
  width: 140vh;
  height: 47.5vw;
  max-height: 100%;
}
.time-line .box_parent .box-wrap:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: left top;
  background-repeat: no-repeat;
  z-index: 1;
}
.time-line .box_parent .box-wrap:after {
  content: "";
  width: calc(100% - 3vw);
  height: calc(100% - 3.5vw);
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border-radius: 15px;
  margin: 2vw 1.5vw 1.5vw 1.5vw;
  z-index: 0;
}
.box_parent .timeline-bg-area {
  width: calc(100% - 3vw);
  height: calc(100% - 3.5vw);
  margin: 2vw 1.5vw 1.5vw 1.5vw;
  position: relative;
  z-index: 2;
}
.time-line .bg {
  position: absolute;
  transition: 0.3s;
  transform: scale(1);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* opacity: 0; */
  z-index: 1;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100%;
  display: none;
}
.time-line .bg img {
  width: 100%;
  height: 100%;
}

/*.timeline-bg-area .bg{*/
/*  cursor: pointer;*/
/*}*/

.timeline-bg-area .pointer {
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 5;
}

.timeline-bg-area .top {
  transform-origin: top;
}
.timeline-bg-area .top.left {
  transform-origin: top left;
}
.timeline-bg-area .top.right {
  transform-origin: top right;
}
.timeline-bg-area .left {
  transform-origin: left;
}
.timeline-bg-area .right {
  transform-origin: right;
}
.timeline-bg-area .bottom {
  transform-origin: bottom center;
}
.timeline-bg-area .bottom.left {
  transform-origin: bottom left;
}
.timeline-bg-area .bottom.right {
  transform-origin: bottom right;
}
.time-line .bg.on {
  transform: scale(1.15);
  display: none;
  /* display: block;  */
  z-index: 2;
}
.time-line .bg.show {
  display: none;
  /* display: block;  */
  opacity: 1;
}
.time-pop-inner{
  display: none;
}
.time-line-pop{
    position: absolute;
    z-index: 30;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    max-width: 1050px;
    width: 80vw;
}
.question-layer{
    width:100%;
    display: flex;
    margin-bottom:65px;
}
.question-layer .select{
    background: #fff;
    margin-right: 29px;
    border-width: 6px;
    border-color: #9cc813;
    border-style: solid;
    padding:32px;
    width: calc((100% - 20px) / 2);
    border-radius: 30px;
    max-height:350px;
    height:28vw;
    position: relative;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(48, 48, 48, .4);
}
.question-layer .select:last-child{
    margin-right:0
}
.question-layer .select:hover{
    border-color: #f9be00;
}
.question-layer .select:active{
    outline:none;
    border:6px solid #f9be00;
}
.question-layer .select p{
    font-family: 'NanumBarunGothic';
    font-size:1.6vw;
    font-weight: 600;
    line-height: 1.3;
    position: relative;
    z-index: 2;
    letter-spacing: 0;
}
.question-layer img{
    position: absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    width:100%;
    z-index: 1;
}
.shadow{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,.75);
    z-index: 20;
}



/*에피소드 별 설정*/
/*HHT1*/
/*EP01*/
.time-line .hht1-timeline-ep01 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep01/sample.png); */
  background-image: url(../images/time-line/hht1_ep01/hht1-timeline-bg01.png);
}
.hht1-timeline-ep01 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep01/hht1-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP02*/
.time-line .hht1-timeline-ep02 .box-wrap {
}
.hht1-timeline-ep02 .box-wrap:before {
}

/*HHT2*/
/*EP01*/
.time-line .hht2-timeline-ep01 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep01/sample.png); */
  background: #fff;
  border: 2px solid #dfe1e0;
}
.hht2-timeline-ep01 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep01/hht2-timeline-bg02.png);
  background-size: auto 100%;
}
.time-line .hht2-timeline-ep02 .box-wrap {
}
.hht2-timeline-ep02 .box-wrap:before {
}

.text_over {
  font-size: 1.3vw !important;
}

/*에피소드 별 설정*/

@media (min-width: 2000px) {

  .time-line-pop{
    max-width: 1450px !important;
  }


  .time-line .box_parent .box-wrap {
    max-width: inherit;
    height: 87%;
  }

  /* 4k 정답 텍스트 사이즈*/
  .question-layer .select p{
    font-family: 'NanumBarunGothic';
    font-size:34px;
    font-weight: 600;
    line-height: 1.3;
    position: relative;
    z-index: 2;
    letter-spacing: 0;
  }

  .question-layer .select{
    max-height:450px;
  }
  .over_hundred{
    font-size: 34px !important;
  }
}

@media (max-width: 1999px) {
  .over_hundred{
    font-size: 1.3vw !important;
  }
}
@media (max-width: 1400px) {
  .time-line .box_parent .box-wrap {
    height: 54.5vw;
  }
}
@media (max-width: 1200px) {
  .time-line .box_parent .box-wrap {
    width: 93%;
  }
}

@media (min-height: 1000px) {
  .time-line .box_parent .box-wrap {
    width: 80vw;

    /* 4k 2022-10-20 기존에 min-height 1000으로 작업 되어 있으므로 1080 height 초과로 세분화 하지 않고 1000px 이상일시 사이즈 제한합니다 */
    max-width: 1920px;
    max-height: 1152px;
  }
}
@media (max-height: 768px) {
  .time-line .box_parent {
    padding: 85px 0 15px;
  }
  .time-line .box_parent .box-wrap {
    width: 140vh;
    max-width: 90%;
  }
  .time-line-pop {
    width: 60vw;
  }
  .question-layer {
    margin-bottom: 45px;
  }
  .question-layer .select {
    height: 20vw;
    padding: 22px 30px;
  }
  .question-layer .select p {
    font-size: 1.8vw;
  }
}
@media (max-height: 650px) {
  .time-line .box_parent {
    padding: 75px 0 10px;
  }
  .time-line .box_parent .box-wrap {
    height: 100%;
  }
  .question-layer {
    margin-bottom: 30px;
  }
  .question-layer .select {
    height: 255px;
  }
  .question-layer .select p {
    font-size: 22px;
  }
  .box_parent .timeline-bg-area {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
  .time-line .box_parent .box-wrap:after {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
  .timeline-cursor {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
}


/*타임라인 신규추가 0226 hhj*/
/*EP02*/
.time-line .hht1-timeline-ep02 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep02/sample.png); */
  background-image: url(../images/time-line/hht1_ep02/hht1-ep02-timeline-bg01.png);
}
.hht1-timeline-ep02 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep02/hht1-ep02-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP03*/
.time-line .hht1-timeline-ep03 .box-wrap {
  background-image: url(../images/time-line/hht1_ep03/sample.png);
  background-image: url(../images/time-line/hht1_ep03/hht1-ep03-timeline-bg01.png);
}
.hht1-timeline-ep03 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep03/hht1-ep03-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP04*/
.time-line .hht1-timeline-ep04 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep04/sample.png); */
  background-image: url(../images/time-line/hht1_ep04/hht1-ep04-timeline-bg01.png);
}
.hht1-timeline-ep04 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep04/hht1-ep04-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP05*/
.time-line .hht1-timeline-ep05 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep05/sample.png); */
  background-image: url(../images/time-line/hht1_ep05/hht1-ep05-timeline-bg01.png);
}
.hht1-timeline-ep05 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep05/hht1-ep05-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP06*/
.time-line .hht1-timeline-ep06 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep06/sample.png); */
  background-image: url(../images/time-line/hht1_ep06/hht1-ep06-timeline-bg01.png);
}
.hht1-timeline-ep06 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep06/hht1-ep06-timeline-bg02.png);
  background-size: 100% 100%;
}
/*EP07*/
.time-line .hht1-timeline-ep07 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep07/sample.png); */
  background-image: url(../images/time-line/hht1_ep07/hht1-ep07-timeline-bg01.png);
}
.hht1-timeline-ep07 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep07/hht1-ep07-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP08*/
.time-line .hht1-timeline-ep08 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep08/sample.png); */
  background-image: url(../images/time-line/hht1_ep08/hht1-ep08-timeline-bg01.png);
}
.hht1-timeline-ep08 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep08/hht1-ep08-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP09*/
.time-line .hht1-timeline-ep09 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep09/sample.png); */
  background-image: url(../images/time-line/hht1_ep09/hht1-ep09-timeline-bg01.png);
}
.hht1-timeline-ep09 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep09/hht1-ep09-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP10*/
.time-line .hht1-timeline-ep10 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep10/sample.png); */
  background-image: url(../images/time-line/hht1_ep10/hht1-ep10-timeline-bg01.png);
}
.hht1-timeline-ep10 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep10/hht1-ep10-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP11*/
.time-line .hht1-timeline-ep11 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep11/sample.png); */
  background-image: url(../images/time-line/hht1_ep11/hht1-ep11-timeline-bg01.png);
}
.hht1-timeline-ep11 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep11/hht1-ep11-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP12*/
.time-line .hht1-timeline-ep12 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep12/sample.png); */
  background-image: url(../images/time-line/hht1_ep12/hht1-ep12-timeline-bg01.png);
}
.hht1-timeline-ep12 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep12/hht1-ep12-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP13*/
.time-line .hht1-timeline-ep13 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep13/sample.png); */
  background-image: url(../images/time-line/hht1_ep13/hht1-ep13-timeline-bg01.png);
}
.hht1-timeline-ep13 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep13/hht1-ep13-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP14*/
.time-line .hht1-timeline-ep14 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep14/sample.png); */
  background-image: url(../images/time-line/hht1_ep14/hht1-ep14-timeline-bg01.png);
}
.hht1-timeline-ep14 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep14/hht1-ep14-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP15*/
.time-line .hht1-timeline-ep15 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep15/sample.png); */
  background-image: url(../images/time-line/hht1_ep15/hht1-ep15-timeline-bg01.png);
}
.hht1-timeline-ep15 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep15/hht1-ep15-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP16*/
.time-line .hht1-timeline-ep16 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep16/sample.png); */
  background-image: url(../images/time-line/hht1_ep16/hht1-ep16-timeline-bg01.png);
}
.hht1-timeline-ep16 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep16/hht1-ep16-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP17*/
.time-line .hht1-timeline-ep17 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep17/sample.png); */
  background-image: url(../images/time-line/hht1_ep17/hht1-ep17-timeline-bg01.png);
}
.hht1-timeline-ep17 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep17/hht1-ep17-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP18*/
.time-line .hht1-timeline-ep18 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep18/sample.png); */
  background-image: url(../images/time-line/hht1_ep18/hht1-ep18-timeline-bg01.png);
}
.hht1-timeline-ep18 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep18/hht1-ep18-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP19*/
.time-line .hht1-timeline-ep19 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep19/sample.png); */
  background-image: url(../images/time-line/hht1_ep19/hht1-ep19-timeline-bg01.png);
}
.hht1-timeline-ep19 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep19/hht1-ep19-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP20*/
.time-line .hht1-timeline-ep20 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep20/sample.png); */
  background-image: url(../images/time-line/hht1_ep20/hht1-ep20-timeline-bg01.png);
}
.hht1-timeline-ep20 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep20/hht1-ep20-timeline-bg02.png);
  background-size: auto 100%;
}


/*hht2 - EP02*/
.time-line .hht2-timeline-ep02 .box-wrap {
   background-image: url(../images/time-line/hht2_ep02/sample.png);
}
.hht2-timeline-ep02 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep02/hht2-ep02-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP03*/
.time-line .hht2-timeline-ep03 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep03/sample.png); */
}
.hht2-timeline-ep03 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep03/hht2-ep03-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP04*/
.time-line .hht2-timeline-ep04 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep04/sample.png); */
}
.hht2-timeline-ep04 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep04/hht2-ep04-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP05*/
.time-line .hht2-timeline-ep05 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep05/sample.png); */
}
.hht2-timeline-ep05 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep05/hht2-ep05-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP06*/
.time-line .hht2-timeline-ep06 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep06/sample.png); */
}
.hht2-timeline-ep06 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep06/hht2-ep06-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP07*/
.time-line .hht2-timeline-ep07 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep07/sample.png); */
}
.hht2-timeline-ep07 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep07/hht2-ep07-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP08*/
.time-line .hht2-timeline-ep08 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep08/sample.png); */
}
.hht2-timeline-ep08 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep08/hht2-ep08-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP09*/
.time-line .hht2-timeline-ep09 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep09/sample.png); */
}
.hht2-timeline-ep09 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep09/hht2-ep09-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP10*/
.time-line .hht2-timeline-ep10 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep10/sample.png); */
}
.hht2-timeline-ep10 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep10/hht2-ep10-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP11*/
.time-line .hht2-timeline-ep11 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep11/sample.png); */
}
.hht2-timeline-ep11 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep11/hht2-ep11-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP12*/
.time-line .hht2-timeline-ep12 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep12/sample.png); */
}
.hht2-timeline-ep12 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep12/hht2-ep12-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP13*/
.time-line .hht2-timeline-ep13 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep13/sample.png); */
}
.hht2-timeline-ep13 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep13/hht2-ep13-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP14*/
.time-line .hht2-timeline-ep14 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep14/sample.png); */
}
.hht2-timeline-ep14 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep14/hht2-ep14-timeline-bg02.png);
  background-size: auto 100%;
}

/*hht2 - EP15*/
.time-line .hht2-timeline-ep15 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep15/sample.png); */
}
.hht2-timeline-ep15 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep15/hht2-ep15-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP16*/
.time-line .hht2-timeline-ep16 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep16/sample.png); */
}
.hht2-timeline-ep16 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep16/hht2-ep16-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP17*/
.time-line .hht2-timeline-ep17 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep17/sample.png); */
}
.hht2-timeline-ep17 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep17/hht2-ep17-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP18*/
.time-line .hht2-timeline-ep18 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep18/sample.png); */
}
.hht2-timeline-ep18 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep18/hht2-ep18-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP19*/
.time-line .hht2-timeline-ep19 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep19/sample.png); */
}
.hht2-timeline-ep19 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep19/hht2-ep19-timeline-bg02.png);
  background-size: auto 100%;
}
/*hht2 - EP20*/
.time-line .hht2-timeline-ep20 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep20/sample.png); */
}
.hht2-timeline-ep20 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep20/hht2-ep20-timeline-bg02.png);
  background-size: auto 100%;
}
.time-line .bg.index{z-index:2;}
/* .time-line .box_parent .box-wrap:after{display: none;} */
