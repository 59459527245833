*{
    caret-color: transparent;
}
.mini-sitcom-new{
    height: 100%;
    overflow: hidden;
    background-color: rgb(243, 247, 217);
    background-image: url(../images/background-pattern.png);
    background-repeat: repeat;
    background-size: auto;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
}
.mini-sitcom-new>div{
    position: relative;
    height: 100%;
}
.mini-sitcom-new .contents{
    min-height: 100%;
}

.mini-sitcom-new .screen{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 60px;
}
.sitcom-box{
    height: 100%;
}
.sitcom-box .screen>div{
    position: relative;
}
.titleA{
    font-family: 'NanumBarunGothic';
    font-size: 2.8vw;
    color: #ff6600;
    display:inline-block;
    font-weight: 600;
    margin-bottom:1.5vh;
    position: relative;
    padding-left: 62px;
}
.titleA span{
    font-weight: 800;
}
.titleA:before{
    content: '';
    width: 52px;
    height:36px;
    background: url(../images/activity-minisitcom/key_icon.png)center no-repeat;
    position: absolute;
    left:0;
    top:0;
}
.key_box img{
    display: block;
    max-height: 55vh;
    margin: 0 auto;
}
.play_btn{
    position: absolute;right:0;top:0;
    width: 6vw;
    cursor: pointer;
}
.play_btn img{
    width: 100%;
    display: block;
}

.mini-position{
    position: relative;
}
.mini-position>img{
    width: 100%;
    max-height: 50vh;
}
.bubble-wrap{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
}
.bubble-wrap img{
    position: absolute;
    cursor:pointer;
}
.answer-parent{
    display:block;
}
.answer-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.answer-wrap .choices-list{
    display:flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}
.answer-wrap .choices-list:first-child{
    margin-top:0;
}
.answer-wrap .choices{
    font-size: 2vw;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    display: block;
    padding:10px 10px;
    margin-right:28px;
    font-weight: 600;
    width: 17.1vw;
    border:6px solid #fff;
}
.answer-wrap .choices:last-child{
    margin-right:0;
}
.answer-wrap .choices:hover{
    color:#fc856c;
    border:6px solid #fc856c;
    cursor:url(../images/direction/guide_pointer_resized.png) 25 0, auto;
}

.active{
    color:#fc856c;
    border:6px solid #fc856c !important;
}

.answer-wrap .choices em{
    font-family: 'NanumBarunGothic';
    font-size:28px;
    font-style:normal;
    font-weight: 600;
    display:block;
}
.btm_layer .buttongroup{
    margin-top:2.5vw;
    width: 100%;
}
.answer-wrap.picture img{
    width: 100%;
    max-width: 240px;
}
.answer-wrap.picture .choices{
    width: 15vw;
    min-width: 230px;
}

.blank-bubble{
    position: relative;
}
.result-wrap {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -45%);
    background-color: #fff;
    border-radius: 80px;
    z-index: 25;
    padding: 46px 73px;
    max-width: 1235px;
    width: 80vw;
    height:83vh;
}
.result-wrap .imgArea{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0;
    height: calc(100% - 120px);
}
.result-wrap img{
    max-height: 52vh;
    max-width: 100%;
    display: block;
}
.video-wrap{
    width: 100%;
    height: 100%;
}
.video-wrap img, .video-wrap video{
    width: 100%;
    height: 100%;
}

@media (max-width: 1254px), (max-height:652px){
    .key_box img{
        width: 80% !important;
        height: 80% !important;
    }
}


@media (max-width: 1024px), (max-height:768px) {
    .mini-sitcom-new .screen{
        padding: 40px;
    }
    .titleA{
        font-size: 40px;
    }
    .play_btn{
        width: 10vh;
    }
    .answer-wrap .choices-list{
        margin-top:13px;
    }
    .answer-wrap.picture .choices{
        width: calc((90% - 36px) / 3);
        min-width: auto;
    }
    .answer-wrap .choices{
        margin-right:18px;
        border-width:3px;
    }
    .answer-wrap .choices:hover{
        border-width: 3px;
    }
    .answer-wrap.word .choices{
        font-size: 2.3vw;
        width: 20.1vw;
    }
    .answer-wrap .choices em{
        font-size:2.2vw;
    }
    .result-wrap{
        min-height: auto;
        border-radius: 50px;
        width: 75.09vw;
        padding: 35px 50px;
    }
    .key_box {
        margin-top: 30px;
    }
    .key_box img {
        width: 100%;
        height: 100%;
    }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .mini-sitcom-new .screen{
        padding: 30px;
    }
    .titleA{
        font-size: 33px;
        margin-bottom: 15px;
        padding-left: 43px;
    }
    .titleA:before{
        width: 35px;
        height: 26px;
        background-size: 100%;
    }
    /*.key_box img{*/
    /*    max-height: 59vh;*/
    /*}*/
    .mini-position>img{
        height: 62vh;
        min-height: 100%;
        max-height: 53vh;
    }
    .answer-wrap .choices{
        width: 20.1vw;
        padding: 8px 10px;
        border-radius: 10px;
        border-width: 3px;
    }
    .answer-wrap .choices em {
        font-size: 20px;
    }
    .result-wrap{
        padding: 25px 28px;
        width: 83.09vw;
        height: 82vh;
    }
    .result-wrap .imgArea{
        margin:2vh 0;
    }
    .answer-wrap.picture .choices {
        width: calc((90% - 155px) / 3);
        min-width: 152px;
    }
    .key_box img{
        width:auto;
    }
}
@media (max-width:900px) {
    .key_box img{
        width:100%;
    }
}

@media (min-width:2000px) {
    .key_box img{
        display: block;
        max-height: 28vh;
        margin: 0 auto;
    }

    /* 2022-10-06 */
    .answer-wrap .choices{
        cursor: pointer;
        font-size: 1.5vw;
        background-color: #fff;
        border-radius: 15px;
        text-align: center;
        display: block;
        padding:10px 10px;
        margin-right:28px;
        font-weight: 600;
        width: 9vw;
        border:6px solid #fff;
    }

    .answer-wrap.picture .choices{
        width: 9vw;
        min-width: 230px;
    }

    .result-wrap {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);
        background-color: #fff;
        border-radius: 80px;
        z-index: 25;
        padding: 46px 73px;
        max-width: 1235px;
        width: 80vw;
        height:860px;
    }

    /*
    .result-wrap .imgArea{
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
        height: calc(90% - 120px);
    }
    */
}
