/*Layout CSS*/
/*hhj 관성스크롤 css*/
/* #root {
  position: fixed;
  width: 100%;
  height:100vh;
  overflow: hidden;
  transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
}
#wrap{
  position:fixed;
  width:100%;height:100%;
  overflow:auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  -webkit-overflow-scrolling:touch;
} */
/*hhj 관성스크롤 css*/
*{
    caret-color: transparent;
}
iframe{
    z-index: -1 !important;
}

#drmVideoPlayer iframe{
    z-index: auto !important;
}

#contents{
  overflow:hidden;
  height: 100vh;
}

.route_bar {
  width: calc(100% - 5%);
  height: 88px;
  border-radius: 0 0 50px 50px;
  box-shadow: 0px 11px 3px rgba(50, 50, 50, 0.1);
  margin: 0 auto 30px;
  padding: 20px 40px 20px 15%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 5;
  background-color: #96c93d;
}

.route_bar.route-bar-background-main {
  background-color: #96c93d;
}
.route_bar.route-bar-background-hh1{
  background-color: #96c93d;
}
.route_bar.route-bar-background-hh2{
  background-color: #08bbcb;
}
.route_bar.route-bar-background-culture {
  background-color: #34add8;
}
.route_icons {
  width: 48px;
  height: 48px;
  background-color: rgba(50, 50, 50, 0.1);
  border-radius: 50%;
  margin-right: 15px;
}
.route_icons_img {
  width: 24px;
}

.route_bar p {
  text-align: center;
  font-size: 38px;
  color: #fffffe;
}
.route_bar p span {
  font-family: "NanumSquareRound", sans-serif;
  color: #fff;
  font-size: 40px;
}

.right_icon {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.right_icon > * {
  margin-right: 40px;
}
.right_icon > *:last-child {
  margin-right: 0;
}
.paging {
  display: flex;
  align-items: center;
  padding: 6px 22px 4px 22px;
  border-radius: 55px;
  background-color:#87b537;
}
.route_bar.route-bar-background-hh1 .paging{
  background-color: #87b537;
}
.route_bar.route-bar-background-hh2 .paging{
  background-color: #9dc3e6;
}
.paging span {
  display: block;
  font-size: 27px;
  color: #fff;
  opacity: 0.5;
  font-weight: 300;
}
.paging span.current_num {
  opacity: 1;
  font-weight: 600;
}
.help_icon_img {
  cursor: pointer;
  width: 21px;
  height: 41px;
}
.cancel_icon_img {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.home_icon_img {
  margin-right: 10px;
}

.box_parent {
  width: 100%;
  height: 100vh;
  padding: 120px 0px 50px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.box_parent .box-wrap {
  max-width: 1235px;
  height: 100%;
  width: 80vw;
  background-color: rgb(255, 255, 255);
  border-radius: 80px;
  border: 1px solid rgba(50, 50, 50, 0.2);
  overflow: visible;
  margin: 0px auto;
  flex-wrap: wrap;
  position: relative;
}
.box_parent .box-wrap .box{
  max-width: inherit;
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  margin:0 !important;
}
.box_parent .box {
  max-width: 1235px;
  height: 100%;
  width: 80vw;
  background-color: rgb(255, 255, 255);
  border-radius: 80px;
  border: 1px solid rgba(50, 50, 50, 0.2);
  overflow: visible;
  margin: 0px auto;
  flex-wrap: wrap;
}
.box_parent .screen {
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 60px;
}
.left-button {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translate(-100%, -50%);
  cursor: pointer;
}
.left-button img {
  width: 95px;
}
.right-button {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translate(100%, -50%);
  cursor: pointer;
}
.right-button img {
  width: 95px;
}
.modal_width{
  display: none;
}
.modal_guide{

  position: fixed;
  left: 50%;
  top: 50%;
    transform: translate(-50%, -50%);
  z-index: 30;
}
.modal_overlay{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0,0,0,.75);
    z-index: 20;
}
.modal_wrapper{
    position: absolute;
    left: 50%;
    top:50%;
    border-radius: 40px;
    transform: translate(-50%, -35%);
    z-index:30;
    background-color: #fff;
    padding: 50px 80px 45px 80px;
    min-width: 673px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.studio_modal_override{
    transform: translate(-50%, 100%) !important;
}
.wordconstest_modal_override{
    background-color: rgba(0,0,0,0) !important;
}
.modal_wrapper:after{
    content: '';
    position: absolute;
    left: 50%;
}
.modal_wrapper.great-pop:after{
    width: 240px;
    height: 238px;
    background: url(../images/pop-default/pop-great.png)center top no-repeat;
    background-size:100%;
    top:-238px;
    transform:translateX(-50%);
}
.modal_wrapper.close-pop:after{
    width: 238px;
    height: 225px;
    background: url(../images/pop-default/pop-close.png)center top no-repeat;
    background-size:100%;
    top:-209px;
    transform:translateX(-75%);
}
.modal_wrapper.exit-pop:after{
    width: 354px;
    height: 232px;
    background: url(../images/pop-default/pop-exit.png)center top no-repeat;
    background-size:100%;
    top:-231px;
    transform:translateX(-50%);
}
.modal_wrapper.song-pop:after{
    width: 231px;
    height: 227px;
    background: url(../images/pop-default/pop-song.png)center top no-repeat;
    background-size:100%;
    top:-220px;
    transform: translateX(-64%);
}
.modal_inner .title{
    font-family: 'NanumBarunGothic';
    font-size:2.5vw;
    color:#585858;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 1.3;
}
.exit-pop .modal_inner .title{
    font-size:2.5vw;
    line-height: 1.3;
}
.modal_inner .buttongroup{
    margin-top:40px;
    display: flex;
}
.modal_inner .pop-close{
    width: 30px;
    height: 30px;
    position: absolute;
    right:40px;
    top:36px;
    background: url(../images/home-new/pop-close-btn.png)center no-repeat;
    background-size: 100%;
}
.buttongroup.img-btn span{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    display: block;
    margin-right: 18px;
    cursor: pointer;
    background-color: #469be4;
    background-position: center;
    background-repeat: no-repeat;
}
.buttongroup.img-btn span.full{
  background:none;
}
.buttongroup.img-btn span.full:hover{
  background: none !important;
}
.buttongroup.img-btn span:hover{
    background-color:#3789dd;
}
.buttongroup.img-btn img{
    width:100%;
    height:100%;
}
.buttongroup span:last-child, .buttongroup.text-btn span:last-child{
    margin-right: 0 !important;
}
.buttongroup.text-btn span{
    font-family: 'NanumBarunGothic';
    font-size:2.5vw;
    text-align: center;
    display: block;
    margin-right: 14px;
    padding: 5px 10px 1px 10px;
    width: 50%;
    border-radius: 78px;
    cursor: pointer;
    max-width: 230px;
}
.close-button{
  position: absolute;
  right: -15px;
  top: -15px;
}
.line-btn {
  border: 3px solid #6dcde3;
  color: #6dcde3;
}
.line-btn:hover {
  background-color: #58bad1;
  border: 3px solid #58bad1;
  color: #fff;
}
.full-btn {
  border: 3px solid #6dcde3;
  background-color: #6dcde3;
  color: #fff;
}
.full-btn:hover {
  background-color: #58bad1;
  border: 3px solid #58bad1;
}
.restart-btn {
  background-image: url(../images/pop-default/restart-btn.png);
}
.home-btn {
  background-image: url(../images/pop-default/home-btn.png);
}
.next-btn {
  background-image: url(../images/pop-default/next-btn.png);
}

.btm_layer {
  position: absolute;
  left: 50%;
  bottom: 25px;
  background-color: #b8e8f3;
  border-radius: 50px;
  width: 98%;
  padding: 40px 0;
  transform: translateX(-50%);
  box-shadow: 0px 11px 3px rgb(50 50 50 / 10%);
  z-index: 5;
  display:none;
  max-width: 1200px;
  width: 77vw;
}

.layer-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: #6e6e6e;
  display: block;
  background-image: url(../images/activity-sitcom/cancel-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  cursor: pointer;
}
.btm_layer p {
  font-size: 3.2vw;
  font-weight: 600;
  margin-top: 57px;
  text-align: center;
}
.text-wrap {
  height: 6.6vw;
  padding-top: 10px;
  display: flex;
    align-items: center;
    justify-content: center;
}
.text-wrap p {
  font-size: 2.6vw;
  margin-top: 0 !important;
  display: block;
  width: 100%;
  line-height: 1.1;
}

.text_override {
    font-size: 2.5vw !important;
}
.btm_layer p .point {
  color: #f11111;
  font-weight: 600;
}
.btm_layer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
/*버튼스타일*/
.check-btn {
  font-family: "NanumSquareRound", sans-serif;
  font-size: 30px;
  color: #fff;
  background-color: #cecfd1;
  background-image: url(../images/quiz-time/check-default.png);
  background-position: left 22px center;
  background-repeat: no-repeat;
  background-size: 39px 39px;
  padding: 18px 35px 17px 76px;
  border-radius: 79px;
  font-weight: 700;
  cursor: pointer;
}
.check-btn.on {
  background-color: #469be4;
  background-image: url(../images/quiz-time/check-on.png);
}
.buttongroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-purple {
  font-size: 30px;
  display: inline-block;
  padding: 17px 35px;
  background: #fff;
  color: #8c10d8;
  min-width: 229px;
  border-radius: 79px;
}
.btn-purple:hover,
.btn-purple.on {
  background: #8c10d8;
  color: #fff;
}
.btn-blue {
  font-size: 2.5vw;
  display: inline-block;
  padding: 1vw 3vw;
  background: #469be4;
  color: #fff;
  min-width: 229px;
  border-radius: 79px;
}
.btn-blue:hover,
.btn-blue.on{
  background:#3789dd;
}

.btn-record {
    font-size: 1.6vw;
    display: inline-block;
    padding: 0.5vw 1.5vw;
    background: #469be4;
    color: #fff;
    border-radius: 79px;
}
.btn-record:hover,
.btn-record.on{
    background:#3789dd;
}


.btn-record-stop {
    font-size: 1.6vw;
    display: inline-block;
    padding: 0.5vw 1.5vw;
    background: #fb2e38;
    color: #fff;
    border-radius: 79px;
}
.btn-record-stop:hover,
.btn-record-stop.on{
    background:#fb2e38;
}

.btn-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
}
.btn-area > img {
  cursor: pointer;
}

.guide-wrap {
  position: relative;
  /*left: 50%;*/
  /*top: 50%;*/
  /*transform: translate(-50%, -50%);*/
  background-color: #fff;
  border-radius: 0px 0px 40px 40px;
  z-index: 30 !important;
  padding: 46px 73px;
  max-width: 1235px;
  width: 78vw;
  height: 75vh !important;
}


.guide-wrap .screenContainer {
  margin:3vh 0;
}
.guide-wrap .btnGuideStartDim{
  text-align: center;
}
.guide-wrap .inner {
  position: relative;
  padding: 0 145px;
  width: 100%;
}


.guide_title{
    width:100%;
    /*height:8vh;*/
    position:relative;
    /*top:0;*/
    font-weight: bold;
    font-size:40px;
    background-color: #93CE59;
    z-index:50;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
}
.guide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -36px;
  cursor: pointer;
  width: 30px;
}
.guide-arrow img{
  width:100%;
}
.guide-arrow.btnGuideLeft {
  left: 10%;
}
.guide-arrow.btnGuideRight {
  right: 10%;
}
.guide-wrap .imgArea {
  display: flex;
  align-items: center;
  justify-content: center;
}
.guide-wrap .imgArea {
  margin-bottom: 3.5vh;
    margin-top:3.5vh;
}
.guide-wrap .imgArea img {
  max-height: 50vh;
  max-width: 100%;
  display: block;
}
.guide-close {
  position: absolute;
  right: 73px;
  top: 35px;
  background: none;
    /*z-index: 100;*/
}
.guide-wrap .guideText p {
  font-family: "NanumBarunGothic";
  font-size: 2vw;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
}
.order-bar-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-bar-div .next-circle, .order-bar-div .now-circle {
  margin: 0 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(237, 237, 237);
  cursor: pointer;
}
.order-bar-div .now-circle.now-circle-hh1 {
  background-color: rgb(150, 201, 61);
}
.order-bar-div .now-circle.now-circle-hh2 {
  background-color: rgb(8, 187, 203);
}
.count-wrap{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.4);
}
.countCircle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  display: none;
}
.countCircle img{
  width:100%;
  display:block;
  position:absolute;
  left:0;
  top:0;
}
.record-wrap{
  width:100%;
  display: flex;
    justify-content: center;
    align-items: center;
}
.record-wrap img{
    cursor: pointer;
    margin-right: 20px;
    width: 5.6vw;
    min-width: 55px;
}
.record-wrap img:last-child{
    margin-right: 0;
}

.box_parent .buttonGroup{
  text-align: center;
  position: absolute !important;
  bottom:60px;
  left:50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  height:auto !important;
}
.box_parent .buttonGroup div img{
  width:206px;
  height:79px;
  margin-right:10px;
  cursor:pointer;
}
.box_parent .buttonGroup div:last-child img{
  margin-right:0;
}
.cong-wrap{
  width:100%;
  height:100%;
}
.cong-wrap .screen{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}
.cong-wrap .screen .mainImg{
  margin: 0 auto;
  margin-bottom:10vh;
  width:476px;
}
.cong-wrap .screen .mainImg img{
  width:100%;
}
@media (min-width: 2000px) {

    .route_bar {
      width: 1920px;
      height: 88px;
      border-radius: 0 0 50px 50px;
      box-shadow: 0px 11px 3px rgba(50, 50, 50, 0.1);
      margin: 0 auto 30px;
      padding: 20px 40px 20px 15%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 5;
      background-color: #96c93d;
    }
  /* 2022-10-06 http://localhost:3000/hh1/animation*/
  /* 1235px 로 fhd 초과 사이즈에서 width 고정시 height는 860px로 고정 */

  .box_parent .box-wrap {
      max-width: 1440px;
      height: 860px;
      width: 80vw;
      background-color: rgb(255, 255, 255);
      border-radius: 80px;
      border: 1px solid rgba(50, 50, 50, 0.2);
      overflow: visible;
      margin: 0px auto;
      flex-wrap: wrap;
      position: relative;
  }
    .box_parent .box{
        height: 860px !important;
    }

  /*2022-10-06 http://localhost:3000/hh1/quiz-time*/
  /* (1920 / 16) * (16 * 0.75) FHD 기준으로 75vw사이즈 */

  .guide-wrap{
    max-width: inherit;
    width: 75vw;
    max-width: 1440px;
  }




    /*2022-10-06 http://localhost:3000/hh1/quiz-time 인터액션 요소*/

  .btm_layer {
    position: absolute;
    left: 50%;
    top: calc(50% + 200px);
    background-color: #b8e8f3;
    border-radius: 50px;
    width: 98%;
    padding: 40px 0;
    transform: translate(-50%);
    box-shadow: 0px 11px 3px rgb(50 50 50 / 10%);
    z-index: 5;
    display: none;
    max-width: 1200px;
    width: 77vw;
  }

    .btm_layer {
        padding: 30px 0;
        bottom: auto !important;
    }

    /* 2022-10-06 */
  .record-wrap img{
      cursor: pointer;
      margin-right: 20px;
      width: 3.6vw;
      min-width: 55px;
  }
  /* 2022-10-06 */
  .text-wrap{
      height: 3.6vw;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  /* 2022-10-06 */
  .text-wrap p{
      font-size: 1.8vw;
  }
. text_override{
    font-size: 1.7vw !important;

}

  .modal_wrapper{
    min-width: inherit;
  }
  .buttongroup.text-btn span{
    font-size:2.5vw;
    padding: 0.5vw 1vw;
  }
  .modal_inner .buttongroup {
    margin-top: 3vh;
  }
  .buttongroup.img-btn span{
    width: 3.6vw !important;
    min-width: 70px;
    min-height: 70px;
  }

  .result-wrap img{
      max-height: 52vh;
      width: 3.6vw !important;
      min-width: 55px;
      display: block;
  }
}

@media (min-width: 2000px) and (min-height: 1700px){
    .guide-wrap{
        height: 60vh !important;
    }
}



@media (max-width: 1388px) {
  /*.guide-wrap .guideText p{*/
  /*  font-size:1.9vw !important;*/
  /*}*/
  .guide-wrap {
    height: 74vh !important;
  }
  .route_bar{
    padding: 20px 40px 20px 12%;
  }
  .left-button img {
    width: 80px;
  }
  .right-button img {
    width: 80px;
  }
}

@media (max-width: 1255px) {

    .guide-wrap {
        height: 74vh !important;
    }
    /*.text-wrap p {*/
    /*    font-size: 2.5vw;*/
    /*}*/

    .text_override {
        font-size: 2.4vw !important;
    }

    .guide_title {
        font-size: 30px !important;
    }
}
@media (max-width: 1024px), (max-height: 768px) {
    .box_parent .screen {
        padding: 40px;
    }

    .route_bar {
        height: 70px;
        box-shadow: 0px 7px 2px rgba(50, 50, 50, 0.1);
        border-radius: 0 0 35px 35px;
        padding: 20px 40px 20px 11%;
    }

    .route_icons {
        width: 33px;
        height: 33px;
    }

    .route_icons .route_icons_img {
        width: 16px;
    }

    .route_bar p {
        font-size: 26px;
    }

    .route_bar p span {
        font-size: 32px;
    }

    .right_icon > * {
        margin-right: 25px;
    }

    .paging {
        padding: 3px 15px 2px 15px;
        border-radius: 37px;
    }

    .paging span {
        font-size: 22px;
    }

    .cancel_icon_img {
        width: 27px;
        height: 27px;
    }

    .help_icon_img {
        width: 15px;
        height: 30px;
    }

    .box_parent {
        padding: 90px 0 20px 0;
    }

    .box_parent .box {
        min-height: auto;
        border-radius: 50px;
        width: 75.09vw;
    }

    .right-button {
        right: -1vw;
    }

    .right-button img {
        width: 75px;
        height: 75px;
    }

    .left-button {
        left: -1vw;
    }

    .left-button img {
        width: 75px;
        height: 75px;
    }

    .order-bar-div .now-circle {
        width: 18px;
        height: 18px;
    }

    .order-bar-div .next-circle {
        width: 18px;
        height: 18px;
    }

    .buttongroup img {
        width: 200px;
        height: 77px;
    }

    .order_bar {
        height: 70px;
        border-radius: 35px 35px 0 0;
    }

    .order_bar > div > div > div {
        width: 35px;
        height: 35px;
    }

    .order_bar > div > div > div p {
        font-size: 18px;
    }

    .modal_wrapper {
        padding: 40px 60px 35px 60px;
        min-width: 500px;
        border-radius: 30px;
        transform: translate(-50%, -37%);
    }

    .modal_wrapper.exit-pop {
        width: 490px;
    }

    .modal_wrapper.close-pop {
        transform: translate(-50%, -24%);
    }

    .modal_inner .title {
        font-size: 3vw;
    }

    .exit-pop .modal_inner .title {
        font-size: 2.6vw;
    }

    .modal_inner .buttongroup {
        margin-top: 25px;
    }

    .buttongroup.img-btn span {
        width: 10vh;
        height: 10vh;
        margin-right: 12px;
        min-width: 58px;
        min-height: 58px;
    }

    .modal_wrapper.great-pop:after {
        width: 171px;
        height: 171px;
        top: -169px;
    }

    .modal_wrapper.close-pop:after {
        width: 183px;
        height: 175px;
        top: -161px;
    }

    .modal_wrapper.exit-pop:after {
        width: 296px;
        height: 195px;
        top: -193px;
    }

    .modal_wrapper.song-pop:after {
        width: 195px;
        height: 192px;
        top: -186px;
    }

    .buttongroup.text-btn span {
        font-size: 3vw;
        padding: 7px 11px 3px 10px;
    }

    .check-btn {
        padding: 14px 30px 13px 74px;
        font-size: 26px;
    }

    .btn-purple, .btn-blue {
        font-size: 22px;
        padding: 14px 35px;
        min-width: 195px;
    }

    .btn-blue {
        min-width: 160px;
    }

    .btm_layer {
        padding: 30px 0;
        bottom: 10px;
    }

    .close-button {
        right: -8px;
        top: -8px;
    }

    .layer-close {
        width: 58px;
        height: 58px;
    }

    .btm_layer p {
        margin-top: 5vh;
    }

    .text-wrap {
        height: 8vw;
    }

    .text-wrap p {
        font-size: 2.4vw;
    }

    .text_override {
        font-size: 2.3vw !important;
    }

    .record-wrap img {
        height: 10vh;
        width: auto;
    }

    .btn-area > img {
        height: 10vh;
    }

    .answer-wrap span {
        font-size: 2.5vw;
        margin-right: 15px;
        width: 20.1vw;
    }

    .guide-wrap, .guide-wrap.box {
        min-height: auto;
        /*border-radius: 50px;*/
        width: 78vw;
        padding: 35px 30px;
        /*transform: translate(-50%, -50%);*/
        height: 74vh !important;
    }

    .guide-wrap .inner {
        padding: 0 4vw;
    }

    .guide-wrap .screenContainer {
        margin: 4vh 0;
    }

    .guide-wrap .btnGuideStartDim img {
        width: 200px;
    }

    /*.guide-wrap .guideText p {*/
    /*    font-size: 1.8vw !important;*/

    /*}*/

    /*.guide_title{*/
    /*    border-top-left-radius: 50px;*/
    /*    border-top-right-radius: 50px;*/
    /*}*/

    .guide-arrow {
        margin-top: -17px;
    }

    .guide-arrow img {
        width: 25px;
    }

    .guide-close {
        right: 35px;
        top: 35px;
    }

    .order-bar-div .next-circle {
        width: 18px;
        height: 18px;
    }

    .box_parent .buttonGroup {
        bottom: 40px;
    }

    .box_parent .buttonGroup div img {
        width: 190px;
        height: 70px;
    }

    .cong-wrap .screen .mainImg {
        width: 30vw;
    }

    .guide-close img {
        width: 30px;
    }

    .guide-wrap .imgArea img {
        max-height: 46vh;
    }
}


@media (max-width: 768px){
    .guide-wrap {

        height: 70vh !important;
    }
}

/*태블릿 7인치 이하*/
@media (max-height: 650px) {
  .box_parent .screen{
    padding: 30px;
  }
  .route_bar {
    height: 60px;
    padding-left: 80px;
  }
  .route_bar p {
    font-size: 24px;
  }
  .route_bar p span {
    font-size: 28px;
  }
  .right_icon > * {
    margin-right: 20px;
  }
  .cancel_icon_img {
    width: 22px;
    height: 22px;
  }
  .help_icon_img {
    width: 12px;
    height: 22px;
  }
  .paging {
    padding: 3px 10px 1px 10px;
    border-radius: 32px;
  }
  .paging span {
    font-size: 19px;
  }
  .box_parent {
    padding: 60px 0 20px 0;
    align-items: flex-start;
  }
  .buttongroup img {
    width: 160px;
    height: 64px;
  }
  .box_parent .box-wrap{
    border-radius: 0 0 50px 50px;
    margin: 0 auto;
    width: calc(100% - 150px);
  }
  .box_parent .box {
    margin: 0 auto;
    width: calc(100% - 150px);
    min-width: auto;
    max-height: inherit;
    height: 100%;
    border-radius: 0 0 50px 50px;
  }
  .right-button {
    right: -10px;
  }
  .right-button img {
    width: 52px;
    height: 52px;
  }

  .left-button {
    left: -10px;
  }
  .left-button img {
    width: 52px;
    height: 52px;
  }
  .order-bar-div .next-circle, .order-bar-div .now-circle{
    width: 15px;
    height: 15px;
    margin:0 3px;
  }
  .modal_inner .title {
    font-size: 25px;
  }
  .buttongroup.text-btn span {
    font-size: 21px;
    width: 130px;
    margin-right: 10px;
  }
  .check-btn {
    background-position: left 17px center;
    background-size: 33px 33px;
    padding: 16px 29px 15px 61px;
    font-size: 22px;
  }
  .btm_layer {
    border-radius: 30px;
    bottom: 14px;
    width: 78vw;
    padding: 25px 0;
    box-shadow: 0px 7px 3px rgb(50 50 50 / 10%);
  }
  .layer-close {
    width: 48px;
    height: 48px;
  }
  .btm_layer p {
    margin-top: 3vh;
  }
  .text-wrap {
    height: 78px;
  }
  .text-wrap p {
    font-size: 24px;
  }

  .text_override{
      font-size:20px;
  }

  .btm_layer.answer {
    padding: 25px 0 15px 0;
    width: 80vw;
  }
  .record-wrap img {
    height: auto;
    width: 60px;
    margin-right: 15px;
  }
  .answer-wrap span {
    padding: 7px 10px;
  }
  .btn-area {
    margin-top: 10px;
  }
  .btn-area > img {
    height: auto;
    width: 70px;
  }
  .modal_wrapper {
    padding: 30px 50px 25px 50px;
    min-width: 380px;
    border-radius: 20px;
  }
  .modal_inner .buttongroup.img-btn {
    margin-top: 15px;
  }
  .exit-pop .modal_inner .title {
    font-size: 26px;
  }
  .modal_wrapper.exit-pop:after {
    width: 241px;
    height: 158px;
    top: -157px;
  }
  .modal_wrapper.close-pop:after {
    width: 160px;
    height: 153px;
    top: -140px;
  }
  .modal_wrapper.great-pop:after {
    width: 149px;
    height: 149px;
    top: -148px;
  }
  .modal_wrapper.song-pop:after {
    width: 172px;
    height: 169px;
    top: -164px;
  }
  .guide-wrap, .guide-wrap.box {
    padding: 25px 28px;
    /*border-radius: 25px;*/
    width: 83.09vw;
    height: 73vh !important;
  }

    /*.guide_title{*/
    /*    border-top-left-radius: 25px;*/
    /*    border-top-right-radius: 25px;*/
    /*}*/

  .guide-arrow img {
    width: 18px;
  }
  .guide-close {
    right: 28px;
    top: 25px;
  }
  .guide-close img {
    width: 25px;
  }
  .btn-purple, .btn-blue {
    font-size: 20px;
    padding: 14px 35px;
    min-width: 164px;
  }
  .btn-blue {
    font-size: 20px;
    padding: 14px 35px;
    min-width: 130px;
  }

  .btn-record {
      font-size: 1.6vw;
      padding: 1vw 3vw;
  }

  .btn-record-stop {
      font-size: 1.6vw;
      padding: 1vw 3vw;
  }

  .modal_inner .pop-close{
    width: 25px;
    height: 25px;
    right: 30px;
    top: 25px;
  }
  .box_parent .buttonGroup{
    bottom:30px;
  }
  .box_parent .buttonGroup div img{
    width: 160px;
    height: 60px;
  }
  .guide-wrap .btnGuideStartDim img{
    width:170px;
  }
  .modal_wrapper.close-pop{
    transform: translate(-50%,0);
  }
}
